import React,{useState} from 'react'
import grievanceServices from '../grievanceServices';
import { AccessTime, CalendarMonth, Sync } from '@mui/icons-material';
import moment from 'moment';
import useStaffList from '../../../../hooks/useStaffList';

function ActivityList({grievanceDetails,getMasterLabel}) {
    const [activityList,setActivityList] = useState(null);
    const [staffList,getStaffLabel] = useStaffList();
	const getActivityList = () =>{
		grievanceServices.getActivityById(grievanceDetails.id).then(res=>{
			setActivityList(res.data);
		}).catch(error=>{

		})
	}
    return (
        <>
            <button className='btn btn-primary' onClick={getActivityList}>{activityList !== null ? <Sync/> : "View Activity" } </button>
			<div className='row mt-3'>
				<div className='col-md-12'>
                <ul className="timeline">
                    {activityList && activityList.map(activity=>
                    <li className="timeline-item" key={activity.id}>
                        <span className='badge bg-dark'><CalendarMonth/> {moment(activity.created_at).format("DD-MMM YYYY") }</span>
                        <span className='badge bg-dark ms-2'><AccessTime/> {moment(activity.created_at).format("hh:mm A") }</span>
                        <div className='card border-0 mt-1 mb-3'>
                            <div className='card-body'>
                                {activity.activityType ==  1 &&
                                    <div><span className='fw-bold'>{getStaffLabel(activity.userId)} </span>Update Status to  <span className='fw-bold'> "{getMasterLabel('grievance-status',activity.activityId)}"</span></div>
                                }
                                {activity.activityType ==  2 &&
                                    <div><span className='fw-bold'>{getStaffLabel(activity.userId)} </span>Assign Grivance To  <span className='fw-bold'> "{getStaffLabel(activity.activityId)}"</span></div>
                                }
                                <span className="blockquote-footer">{getStaffLabel(activity.userId)} </span>
                            </div>
                        </div>
                    </li>
                    )}
                    {activityList &&
                        <li className="timeline-item">
                            <span className='badge bg-dark'><CalendarMonth/> {moment(grievanceDetails.created_at).format("DD-MMM YYYY") }</span>
                            <span className='badge bg-dark ms-2'><AccessTime/> {moment(grievanceDetails.created_at).format("hh:mm A") }</span>
                            <div className='card border-0 mt-1 mb-3'>
                                <div className='card-body'>
                                    <div><span className='fw-bold'>{grievanceDetails.complainantName} </span>Registered complaint  <span className='fw-bold'></span></div>
                                </div>
                            </div>
                        </li>
                    }
                </ul>
				</div>
			</div>
        </>
    )
}

export default ActivityList