import React from 'react'
import moment from 'moment'
import { CircularProgress } from '@mui/material'
import { Link } from 'react-router-dom'

function GrievanceCardList({grievanceList,getMasterLabel}) {
  return (
        <div className='row'>
            {!grievanceList &&
                <div className='col-md-12 text-center'>
                    <CircularProgress/>
                </div>
            }
            {grievanceList && grievanceList.map(grievanceDetails=>
                <div className='col-md-3 col-sm-12' key={grievanceDetails.id}>
                    <div className='card'>
                        <Link to={ "/grievance/details/"+grievanceDetails.id } ><img className="card-img-top" src={grievanceDetails.photo} style={{height:300}}  alt={ grievanceDetails.details} /></Link>
                        <div className='card-body'>
                            <div><span className='fw-bold'>Addreess : </span>{grievanceDetails.address} {grievanceDetails.addressHelper}</div>
                            <div><span className='fw-bold'> Complainant Name : </span>{ grievanceDetails.complainantName}</div>
                            <div><span className='fw-bold'> Complainant Phone : </span>{ grievanceDetails.phone}</div>
                            <div><span className='fw-bold'>Status : </span>{ getMasterLabel("grievance-status", grievanceDetails.grievanceStatus)}</div>
                            <div><span className='fw-bold'> Grievance About : </span>{ getMasterLabel("grievance-type", grievanceDetails.grievanceType)}</div>
                            <div><p> <span className='fw-bold'></span>{ grievanceDetails.details} </p> </div>
                            <div><span className='fw-bold'>Reg Date : </span>{ moment(grievanceDetails.created_at).format("LLLL") }</div>
                            <div><span className='fw-bold'>Last Update : </span>{ moment(grievanceDetails.updated_at).format("LLLL") }</div>

                            <a target='_blank' href={"https://www.google.com/maps/dir//"+grievanceDetails.latitude+", "+grievanceDetails.longitude} >Show Direction on Map</a>

                        </div>
                        { grievanceDetails.grievanceStatus === 1 &&
                            <div className={ 'border border-danger border-2 mb-0'}>
                            </div>
                        }

                        { grievanceDetails.grievanceStatus === 2 &&
                            <div className={ 'border border-warning border-2 mb-0'}>
                            </div>
                        }

                        { grievanceDetails.grievanceStatus === 3 &&
                            <div className={ 'border border-success border-2 mb-0'}>
                            </div>
                        }
                    
                    </div>
                </div>
            )}
                    
            
        </div>
    )
}

export default GrievanceCardList