import React from 'react'
import { CircularProgress } from '@mui/material'
import moment from 'moment/moment'
import { Link } from 'react-router-dom'
import { Directions } from '@mui/icons-material'

function GrivanceTabularList({grievanceList,getMasterLabel,grievanceStats}) {
  	return (
    	<div className='row'>
            {!grievanceList &&
                <div className='col-md-12 text-center'>
                    <CircularProgress/>
                </div>
            }
            {grievanceList &&
                <>
                <div className='col-md-9'>
                    <table className='table'>
                        <thead>
                            <tr>
                                <th>Grievance Id</th>
                                <th>Complainant Name</th>
                                <th>Complainant Phone</th>
                                <th>About</th>
                                <th>Details</th>
                                <th>Date</th>
                                <th>Status</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {grievanceList && grievanceList.map(grievance=>
                                <tr key={grievance.id}>
                                    <td> <Link to={"/grievance/details/"+grievance.id} > {grievance.id} </Link></td>
                                    <td>{grievance.complainantName}</td>
                                    <td>{grievance.phone}</td>
                                    <td>{getMasterLabel('grievance-type',grievance.grievanceType)}</td>
                                    <td>{grievance.details}</td>
                                    <td>{moment(grievance.created_at).format("LLL")}</td>
                                    <td>{getMasterLabel('grievance-status',grievance.grievanceStatus)}</td>
                                    <td><a target='_blank' href={"https://www.google.com/maps/dir//"+grievance.latitude+", "+grievance.longitude} ><Directions/></a></td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
                
                <div className='col-md-3'>
                    
                        <table className='table'>
                            <tbody>
                                <tr className='table-danger'>
                                    <th>Pending</th>
                                    <td>{grievanceStats.progress.pending}</td>
                                </tr>
                                <tr className='table-warning'>
                                    <th>In Progress</th>
                                    <td>{grievanceStats.progress.inProgress}</td>
                                </tr>
                                <tr className='table-success'>
                                    <th>Closed</th>
                                    <td>{grievanceStats.progress.closed}</td>
                                </tr>
                                <tr className='table-dark'>
                                    <th>Total</th>
                                    <td>{grievanceStats.progress.total}</td>
                                </tr>
                            </tbody> 
                        </table>
                    
                    
                        <table className='table'>
                            <tbody>
                                {grievanceStats.grievanceTypeList.map(gt=>
                                    <tr key={gt.value}>
                                        <th>{gt.label}</th>
                                        <td>{gt.count}</td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    
                </div>
                </>
            }
        </div>
  	)
}

export default GrivanceTabularList