import { Dialog, DialogContent, DialogTitle } from '@mui/material';
import React,{useState,useEffect} from 'react'
import businessServices from '../businessServices';
import GoogleMapReact from 'google-map-react';
import { ArrowLeft, PinDrop } from '@mui/icons-material';
const apiKey = process.env.REACT_APP_MAP_API_KEY;

const AnyReactComponent = ({ businessDetails }) => {
    return (
        <div style={{position:"absolute",bottom:0, left :-10  }}> 
            <PinDrop style={{fontSize:30}} className='text-danger'  /> 
            {/* <div className='bg-white p-1'>{businessDetails.title} </div> */}
        </div>
    )
}

function BusinessCordinates({businessDetails,callback}) {

    const [dialogStatus, setDialogStatus] = useState(false);
    const [longitude,setLongitude]  = useState(businessDetails.longitude);
    const [latitude,setLatitude]  = useState(businessDetails.latitude);
    
	const getCordinates = ()=>{
		if(navigator.geolocation){
			navigator.geolocation.watchPosition(_position=>{
                console.log(_position.coords)
                setLatitude(_position.coords.latitude);
                setLongitude(_position.coords.longitude);
			})
		}
	}
    

    const saveCordinates = () =>{
        let payload ={
            id:1,
            latitude  : latitude,
            longitude :  longitude,
        }
        businessServices.save(payload).then(res=>{
            callback();
        }).catch(error=>{
            console.log(error)
        }).finally(()=>{
            setDialogStatus(false);
        })
    }

    return (
        <div className='row'>
            <div className='col-md-12'>
                {businessDetails.longitude===null &&
                    <button className='btn btn-primary' onClick={()=>setDialogStatus(true)} >Setup Business Cordinates</button>
                }
                {businessDetails.longitude!==null && 
                    <>
                        <div style={{width:"100%",height:300}}>
                                <GoogleMapReact
                                    bootstrapURLKeys={{ key: apiKey }}
                                    defaultCenter={{lat:parseFloat(businessDetails.latitude),lng:parseFloat(businessDetails.longitude)}}
                                    defaultZoom={12}
                                >
                                
                                    <AnyReactComponent
                                        lat={parseFloat(businessDetails.latitude)}
                                        lng={parseFloat(businessDetails.longitude)}
                                        businessDetails={businessDetails}
                                    />
                                </GoogleMapReact>
                        </div>
                        <button className='btn btn-primary' onClick={()=>setDialogStatus(true)} >Change Address</button>
                    </>
                }
            </div>

            <Dialog
                open={dialogStatus}
                keepMounted
                onClose={()=>setDialogStatus(false)}
                maxWidth="sm"
                fullWidth={true}
            >
                <DialogTitle>Setup Business Cordinates</DialogTitle>
                <DialogContent>
                    <div className='row'>
                        <div className='col-md-12'>
                            <button className='btn btn-outline-primary my-3' onClick={getCordinates} > Use Current Location</button>
                        </div>
                        <div className='col-md-12'>
                            <div className="form-floating mb-3">
                                <input type="test" className="form-control"  placeholder="" 
                                    value={longitude} 
                                    onChange={(e)=>setLongitude(e.target.value)} 
                                />
                                <label >Longitude</label>
                            </div>
                        </div>

                        <div className='col-md-12'>
                            <div className="form-floating mb-3">
                                <input type="test" className="form-control"  placeholder="" 
                                    value={latitude}
                                    onChange={(e)=>setLatitude(e.target.value)}     
                                />
                                <label >Latitude</label>
                            </div>
                        </div>

                        <div className='col-md-12'>
                            <div className="form-floating mb-3">
                                <button disabled={longitude === "" || longitude == null ||latitude === ""} className='btn btn-primary float-end' onClick={saveCordinates} >Save</button>
                            </div>
                        </div>

                    </div>
                    
                </DialogContent>
            </Dialog>
        </div>
        
    )
}

export default BusinessCordinates