import {useState,useEffect} from 'react'
import hookServices from './hookServices';

function useStaffList(masterNameList,type) {
    const [staffList,setStaffList] = useState(null);

    
    const getStaffListWithSessionStorage = () =>{
        let _staffList  =  JSON.parse(sessionStorage.getItem("staffList"));
        if(_staffList == null){
            hookServices.getStaffDDList().then(res=>{
                let data = res.data;
                data.forEach((staff,i) => {
                    data[i]['assignedGrievanceType'] = staff.assignedGrievanceType !== null ? staff.assignedGrievanceType.split(',').map( Number ) :[];
                });
                console.log(data);
                sessionStorage.setItem("staffList",JSON.stringify(data))
                setStaffList(data);
            }).catch(error=>{
                console.log(error)
            })
        } else {
            setStaffList(_staffList)
        }

    }

    const getStaffList = () =>{
        hookServices.getStaffDDList().then(res=>{
            sessionStorage.setItem("staffList",JSON.stringify(res.data))
            setStaffList(res.data);
        }).catch(error=>{
        })
    }
    useEffect(()=>{
        getStaffListWithSessionStorage();
    },[])

    const getStaffLabel = (id) => {
        try{
            let label = staffList.find(staff => staff.value === id)
            return label.label;
        } catch(e){
            //console.log(type,id);
            return "";
        }
    }
    return [staffList,getStaffLabel];
}

export default useStaffList