import React, { useEffect, useState } from 'react'
import { CircularProgress, Dialog, DialogContent, DialogTitle, IconButton } from '@mui/material'
import { Link } from 'react-router-dom'
import GoogleMapReact from 'google-map-react';
import { ArrowLeft, Directions, HighlightOff, PinDrop, ZoomIn } from '@mui/icons-material';
import moment from 'moment/moment';

import useBusinessInfo from '../../../../hooks/useBusinessInfo'

const apiKey = process.env.REACT_APP_MAP_API_KEY;


const AnyReactComponent = ({ grievanceDetails }) => {
    const [dialogStatus, setDialogStatus] = useState(false);
    return (
        <div >
            <div style={{position:"absolute",left:0,top:0}}>
            <ArrowLeft style={{color:"#000"}} />
            </div>
            <div className='card border border-dark' style={{width:80,height:80, position:"absolute", left:15}}>
                <div className='card-body p-1 '>
                    <img onClick={()=>setDialogStatus(true)} src={grievanceDetails.photo} style={{width:70,height:70}} />
                </div>
                <div className='card-body p-1'>
                    <p className='fw-bold text-danger bg-white rounded p-1 border border-dark'>{grievanceDetails.details}<br/>
                    <a target='_blank' href={"https://www.google.com/maps/dir//"+grievanceDetails.latitude+", "+grievanceDetails.longitude} ><Directions/></a>
                    <Link to={"/grievance/details/"+grievanceDetails.id} ><ZoomIn/></Link>
                    </p>
                </div>
            </div>

            <Dialog
                open={dialogStatus}
                keepMounted
                onClose={()=>setDialogStatus(false)}
                maxWidth="md"
                fullWidth={true}
                
                >   
                
                    <span className='float-end' style={{position:"absolute",top:0,right:0}}><button className='btn btn-sm btn-primary p-1' onClick={()=>setDialogStatus(false)} ><HighlightOff/></button></span>
                
                <DialogContent>
                    <img type="button" style={{width:"100%"}} onClick={()=>setDialogStatus(true)}  src={grievanceDetails.photo} />
                </DialogContent>
            </Dialog>
        </div>
    )
}


const GrievanceMap = ({grievanceList}) =>{

    const [defaultProps,setDefaultProps] = useState(null)

    const businessInfo = useBusinessInfo();

    const setDefaultValues = () =>{
        let _defaultProps = {
            center: {
              lat: parseFloat(grievanceList[0].latitude),
              lng: parseFloat(grievanceList[0].longitude)
            },
            zoom: 13
        };
        if(businessInfo  && businessInfo.longitude == null && businessInfo.latitude===""){
            _defaultProps.center.lat = parseFloat(businessInfo.latitude);
            _defaultProps.center.lng = parseFloat(businessInfo.longitude);
        } 
        setDefaultProps(_defaultProps)
    }

    useEffect(()=>{
        setDefaultValues();
    },[businessInfo])

    

    return(
        
        defaultProps &&
            <GoogleMapReact
            bootstrapURLKeys={{ key: apiKey }}
            defaultCenter={defaultProps.center}
            defaultZoom={defaultProps.zoom}
            >
            {grievanceList.map(grievanceDetails=>
                <AnyReactComponent
                    lat={parseFloat(grievanceDetails.latitude)}
                    lng={parseFloat(grievanceDetails.longitude)}
                    grievanceDetails={grievanceDetails}
                />
            )}
                
            </GoogleMapReact>
           
    )
}

function GrivanceMapList({grievanceList,getMasterLabel,grievanceStats}) {

    const [tableStatus,setTableStatus] = useState(true);

    const [dialogStatus, setDialogStatus] = useState(false);

  	return (
    	<div className='row'>
            {!grievanceList &&
                <div className='col-md-12 text-center'>
                    <CircularProgress/>
                </div>
            }
            {grievanceList && grievanceList.length > 0  &&
                <>
                    <div className={tableStatus ? 'col-md-10' : "col-md-12"}>
                        <div style={{height:"80vh"}}>
                            <GrievanceMap grievanceList={grievanceList} />
                        </div>
                    </div>
                    {tableStatus && 
                        <div className='col-md-2 card p-0'>
                            <div className='card-body p-0'>

                            <button className='btn btn-primary m-2' onClick={()=>setTableStatus(!tableStatus)} >Hide List</button>
                            <button className='btn btn-primary m-2' onClick={()=>setDialogStatus(true)} >Go Full Screen</button>
                            <table className='table'>
                                <thead>
                                    <tr>
                                        <th>Grivance Id</th>
                                        <th>About</th>
                                        <th>Date</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {grievanceList && grievanceList.map(grievance=>
                                        <tr key={grievance.id} className={ grievance.grievanceStatus == 1 ? 'table-danger' :""}>
                                            <td> <Link to={"/grievance/details/"+grievance.id} > {grievance.id} </Link></td>
                                            
                                            <td>{getMasterLabel('grievance-type',grievance.grievanceType)}</td>
                                            
                                            <td>{moment(grievance.create_at).format("ll")}</td>
                                            
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                            </div>
                        </div>
                    }
                    
                    <Dialog
                        open={dialogStatus}
                        keepMounted
                        onClose={()=>setDialogStatus(false)}
                        fullScreen={true}
                        
                    >   
                        
                        {dialogStatus &&
                            <div style={{height:"100vh"}}>
                                <GrievanceMap grievanceList={grievanceList} />
                            </div>
                        }
                        
                        <div style={{position:"fixed",top:30,left:30, width:"100%"}}>
                            <div className='row'>
                                <div className='col-md-1'>
                                    <div className='card bg-danger p-2 text-white'>{grievanceStats.progress.pending} Pending</div>
                                </div>
                                <div className='col-md-1'>
                                    <div className='card bg-warning p-2'>{grievanceStats.progress.inProgress} In progress</div>
                                </div>
                                <div className='col-md-1'>
                                    <div className='card bg-success p-2 text-white'>{grievanceStats.progress.closed} Closed</div>
                                </div>
                                <div className='col-md-1'>
                                    <div className='card bg-dark p-2 text-white'>Total : {grievanceStats.progress.total}</div>
                                </div>
                            </div>
                        </div>

                        <div style={{position:"fixed",bottom:10,left:30, width:"250px"}}>
                            <table className='table table-info shadow'>
                                <tbody style={{fontSize:12}}>
                                    {grievanceStats.grievanceTypeList.map(gt=>{
                                        if(gt.count > 0 ){
                                            return (
                                                <tr key={gt.value}>
                                                    <th style={{padding:3}}>{gt.label}</th>
                                                    <th style={{padding:3}}>{gt.count}</th>
                                                </tr>
                                            )
                                        }
                                    }
                                        
                                        
                                        
                                    )}
                                </tbody>
                            </table>

                        </div>
                        

                        

                    </Dialog>

                </>
            }

        </div>
  	)
}

export default GrivanceMapList