import React, { useState,useEffect } from 'react'
import AppLayout from '../../../../layout/components/AppLayout'
import { useParams } from 'react-router-dom';
import GrievanceDetailsCard from '../components/GrievanceDetailsCard';
import grievanceServices from '../grievanceServices';
import useSystemMasterList from '../../../../hooks/useSystemMasterList'
import GrievanceStatusInput from '../components/GrievanceStatusInput';
import ActivityList from '../components/ActivityList';
import GrievanceAssignInput from '../components/GrievanceAssignInput';
import GrievanceRemark from '../components/GrievanceRemark';
import GrievanceContactList from '../components/GrievanceContactList';

function GrievanceDetailsPage() {
	const {id} = useParams();
	const [grievanceDetails,setGrievanceDetails] = useState(null);
	const [masters,getMasterLabel] = useSystemMasterList(['grievance-type','grievance-status']);
	
	const getGreivanceDetails = () =>{
		grievanceServices.getById(id).then(res=>{
			setGrievanceDetails(res.data);
		}).catch(error=>{

		}).finally(()=>{

		})
	}

	useEffect(()=>{
		getGreivanceDetails();
	},[id])

	return (
		<AppLayout>
			<div className='container'>
				<div className='row'>
					<div className='col-md-12'>
						{masters && grievanceDetails &&
							<span className='float-end my-2 mx-2'>
								<span className='mx-2 fw-bold'>Current Status : </span>
								<GrievanceStatusInput 
									grievanceDetails={grievanceDetails} 
									masters={masters} 
									getMasterLabel={getMasterLabel} 
									callback={getGreivanceDetails} 
								/>
							</span>
						}
						
						{grievanceDetails &&
							<span className='float-end my-2 mx-2'> 
								<span className='mx-2 fw-bold'> Assigned To : </span>
								<GrievanceAssignInput
									grievanceDetails={grievanceDetails} 
									callback={getGreivanceDetails} 
								/>
							</span>
						}
						
					</div>
				
					<div className='col-md-8'>
						{masters && grievanceDetails &&
							<GrievanceDetailsCard grievanceDetails={grievanceDetails} getMasterLabel={getMasterLabel} />
						}
					</div>
					<div className='col-md-4'>

						<div className='row mt-3'>
							<div className='col-md-12'>
								{grievanceDetails &&
									<GrievanceRemark grievanceDetails={grievanceDetails} />
								}
							</div>
							<hr/>
							<div className='col-md-12'>
								{masters && grievanceDetails &&
									<ActivityList grievanceDetails={grievanceDetails} getMasterLabel={getMasterLabel} />
								}
							</div>

							<div className='col-md-12'>
								<GrievanceContactList grievanceDetails={grievanceDetails} />
							</div>
						</div>
					</div>
				</div>
			</div>
		</AppLayout>
	)
}

export default GrievanceDetailsPage