import React,{useState} from 'react'
import AppLayout from '../../../../layout/components/AppLayout'
import DateSelect from '../../../../components/AppInput/DateSelect'
import AppSelect from '../../../../components/AppInput/AppSelect'
import useSystemMasterList from '../../../../hooks/useSystemMasterList'

function GrievanceDashboardPage() {
    const [masters] = useSystemMasterList(['grievance-type','grievance-status']);

    const [filter,setFilter] = useState(null);
    const [greivanceType,setGreivanceType] = useState(null);
    const [greivanceStatus,setGreivanceStatus] = useState(null);

  	return (
          	<AppLayout>
				<div className="container-fluid">
					<div className='row'>
						<div className='col-md-4 mb-3'>
							{masters &&
								<AppSelect
									options={[{value:0,label:"All"},...masters['grievance-type']]}
									value={greivanceType}
									setValue={setGreivanceType}
									label="Select Catrgory" 
									optionLabel = "label"
								/>
							}
						</div>
						<div className='col-md-4 mb-3'>
							{masters &&
								<AppSelect
									options={[{value:0,label:"All"},...masters['grievance-status']]}
									value={greivanceStatus}
									setValue={setGreivanceStatus}
									label="Select Status" 
									optionLabel = "label"
								/>
							}
						</div>
						<div className='col-md-4'>
							<span className='float-end'><DateSelect filter={filter} setFilter={setFilter} /></span>
						</div>
					</div>
					<div>Grievance Dashboard</div>
				</div>
          	</AppLayout>
  )
}

export default GrievanceDashboardPage