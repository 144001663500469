import React,{useState} from 'react'
import GoogleMapReact from 'google-map-react';
import { ArrowLeft, HighlightOff, PinDrop } from '@mui/icons-material';
import moment from 'moment/moment';
import { Dialog, DialogContent, DialogTitle, IconButton } from '@mui/material';
const apiKey = process.env.REACT_APP_MAP_API_KEY;
const AnyReactComponent = ({ grievanceDetails }) => {
    const [dialogStatus, setDialogStatus] = useState(false);
    return (
        <div >
            <div style={{position:"absolute",left:0,top:0}}>
            <ArrowLeft style={{color:"#000"}} />
            </div>
            <div className='card border border-dark' style={{width:110,height:110, position:"absolute", left:15}}>
                <div className='card-body p-1 '>
                    <img type="button" onClick={()=>setDialogStatus(true)}  src={grievanceDetails.photo} style={{width:100,height:100}} />
                </div>
                <div className='card-body p-1'>
                    <p className='fw-bold'>{grievanceDetails.details}</p>
                </div>
            </div>

            <Dialog
                open={dialogStatus}
                keepMounted
                onClose={()=>setDialogStatus(false)}
                maxWidth="md"
                fullWidth={true}
                >   
                    <span className='float-end' style={{position:"absolute",top:0,right:0}}><button className='btn btn-sm btn-primary p-1' onClick={()=>setDialogStatus(false)} ><HighlightOff/></button></span>
                    <DialogContent>
                        <img type="button" style={{width:"100%"}} onClick={()=>setDialogStatus(true)}  src={grievanceDetails.photo} />
                    </DialogContent>
                </Dialog>
        </div>
    )
}
    

function GrievanceDetailsCard({grievanceDetails,getMasterLabel}) {
    

    return (
        
            <div className='card'>
                <div className='card-body p-0'>
                    <div style={{ height: '400px', width: '100%' }}>
                        {grievanceDetails &&
                            
                            <GoogleMapReact
                                bootstrapURLKeys={{ key: apiKey }}
                                defaultCenter={{lat:parseFloat(grievanceDetails.latitude),lng:parseFloat(grievanceDetails.longitude)}}
                                defaultZoom={18}
                            >
                            
                                <AnyReactComponent
                                    lat={parseFloat(grievanceDetails.latitude)}
                                    lng={parseFloat(grievanceDetails.longitude)}
                                    grievanceDetails={grievanceDetails}
                                />
                            </GoogleMapReact>
                        }
                    </div>
                </div>
                <div className='card-body'>
                    <div><span className='fw-bold'>Addreess : </span>{grievanceDetails.address} {grievanceDetails.addressHelper}</div>
                    <div><span className='fw-bold'> Complainant Name : </span>{ grievanceDetails.complainantName}</div>
                    <div><span className='fw-bold'> Complainant Phone : </span>{ grievanceDetails.phone}</div>
                    <div><span className='fw-bold'>Status : </span>{ getMasterLabel("grievance-status", grievanceDetails.grievanceStatus)}</div>
                    <div><span className='fw-bold'> Grievance About : </span>{ getMasterLabel("grievance-type", grievanceDetails.grievanceType)}</div>
                    <div><p> <span className='fw-bold'></span>{ grievanceDetails.details} </p> </div>
                    <div><span className='fw-bold'>Reg Date : </span>{ moment(grievanceDetails.created_at).format("LLLL") }</div>
                    <div><span className='fw-bold'>Last Update : </span>{ moment(grievanceDetails.updated_at).format("LLLL") }</div>

                    <a target='_blank' href={"https://www.google.com/maps/dir//"+grievanceDetails.latitude+", "+grievanceDetails.longitude} >Show Direction on Map</a>

                </div>
                { grievanceDetails.grievanceStatus === 1 &&
                    <div className={ 'border border-danger border-2 mb-0'}>
                    </div>
                }
                { grievanceDetails.grievanceStatus === 2 &&
                    <div className={ 'border border-warning border-2 mb-0'}>
                    </div>
                }
                { grievanceDetails.grievanceStatus === 3 &&
                    <div className={ 'border border-success border-2 mb-0'}>
                    </div>
                }
                { grievanceDetails.grievanceStatus === 4 &&
                    <div className={ 'border border-danger border-2 mb-0'}>
                    </div>
                }
            </div>
        
    )
}

export default GrievanceDetailsCard