import { CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import React,{useState} from 'react'
import useStaffList from '../../../../hooks/useStaffList'
import { useAlert } from '../../../../context/AlertContext';
import AppSelect from '../../../../components/AppInput/AppSelect';
import grievanceServices from '../grievanceServices';

function GrievanceAssignInput({grievanceDetails,callback}) {
    const [staffList,getStaffLabel] = useStaffList();
    const [dialogStatus, setDialogStatus] = useState(false);
	const {AppAlert} = useAlert();
    const [assignee,setAssignee] = useState(null);
    const [saving,setSavingStatus] = useState(false);

    const assignGrievance = () =>{
        if(assignee == null || assignee.value == null){
            AppAlert("No Assignee Selected","error")
            return false;
        }
        setSavingStatus(true);
        grievanceServices.updateAssignee({id:grievanceDetails.id,assignedTo:assignee.value}).then(res=>{
			AppAlert("Assignee Updated")
			setDialogStatus(false);
			callback();
		}).catch(error=>{
			AppAlert("Something went Wrong","error");
		}).finally(()=>{
            setSavingStatus(false)
        })

    }

    return (
        <>
            
            <button className='btn btn-primary' onClick={()=>setDialogStatus(true)} >
                {grievanceDetails.assignedTo === null ?
                    "Not Assigned"
                    :
                    getStaffLabel(grievanceDetails.assignedTo)
                }
            </button>

            <Dialog
                open={dialogStatus}
                keepMounted
                onClose={()=>setDialogStatus(false)}
                maxWidth="sm"
                fullWidth={true}
            >
                <DialogTitle>Select Assignee</DialogTitle>
				<DialogContent>
                    <div className='mt-3' style={{height:300}}>
                        {staffList &&
                            <AppSelect
                                options={[{value:null,label:"--Select One--"},...staffList]}
                                value={assignee}
                                setValue={setAssignee}
                                label="Select Assignee" 
                                optionLabel = "label"
                            />
                        }
                    </div>
				</DialogContent>
                <DialogActions>
                    {saving && <CircularProgress/>}
                    {!saving && <button className='btn btn-primary' onClick={assignGrievance} >Assign</button> }
                </DialogActions>
			</Dialog>
            
        </>
    )
}

export default GrievanceAssignInput


/*
staffList && staffList.map(staff=>
                            <button key={staff.value} 
                                type='button' className="list-group-item list-group-item-action"
                                onClick={()=>assignGrievance(staff.value)}
                            >
                                {staff.label}
                            </button>
                        )}

*/