
import api from "../../../api/api";



const  serialize = (obj) =>{
    var str = [];
    for (var p in obj)
      if (obj.hasOwnProperty(p)) {
        str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
      }
    return str.join("&");
}



const getDashBoardData = (filter) =>{
    return new Promise((resolve, reject)=>{
        api.get("/admin/grievance/dashboard/data?"+serialize(filter)).then( (res)=>{
            if(!res.data.error){
                resolve(res.data);
            } else {
                reject({
                    message: res.data.msg
                })
            }
        }).catch( (error) => {
            reject({
                message: "Something Went Wrog",
                error : error
            })
        })
    })
}


const getList = (filter) =>{
    return new Promise((resolve, reject)=>{
        api.get("/admin/grievance/list?"+serialize(filter)).then( (res)=>{
            if(!res.data.error){
                resolve(res.data);
            } else {
                reject({
                    message: res.data.msg
                })
            }
        }).catch( (error) => {
            reject({
                message: "Something Went Wrog",
                error : error
            })
        })
    })
}


const getUserGrievanceList = (filter) =>{
    return new Promise((resolve, reject)=>{
        api.get("/admin/grievance/user/list?"+serialize(filter)).then( (res)=>{
            if(!res.data.error){
                resolve(res.data);
            } else {
                reject({
                    message: res.data.msg
                })
            }
        }).catch( (error) => {
            reject({
                message: "Something Went Wrog",
                error : error
            })
        })
    })
}


const getAssignedGrievanceList = (filter) =>{
    return new Promise((resolve, reject)=>{
        api.get("/admin/grievance/assigned/list?"+serialize(filter)).then( (res)=>{
            if(!res.data.error){
                resolve(res.data);
            } else {
                reject({
                    message: res.data.msg
                })
            }
        }).catch( (error) => {
            reject({
                message: "Something Went Wrog",
                error : error
            })
        })
    })
}


const getById = (id) =>{
    return new Promise((resolve, reject)=>{
        api.get("/admin/grievance/details/"+id).then( (res)=>{
            if(!res.data.error){
                resolve(res.data);
            } else {
                reject({
                    message: res.data.msg
                })
            }
        }).catch( (error) => {
            reject({
                message: "Something Went Wrog",
                error : error
            })
        })
    })
}

const getByPhone = (phone) =>{
    return new Promise((resolve, reject)=>{
        api.get("/admin/grievance/search/"+phone).then( (res)=>{
            if(!res.data.error){
                resolve(res.data);
            } else {
                reject({
                    message: res.data.msg
                })
            }
        }).catch( (error) => {
            reject({
                message: "Something Went Wrog",
                error : error
            })
        })
    })
}

const getActivityById = (id) =>{
    return new Promise((resolve, reject)=>{
        api.get("/admin/grievance/activity/"+id).then( (res)=>{
            if(!res.data.error){
                resolve(res.data);
            } else {
                reject({
                    message: res.data.msg
                })
            }
        }).catch( (error) => {
            reject({
                message: "Something Went Wrog",
                error : error
            })
        })
    })
}

const updateStatus = (payload) =>{
    return new Promise((resolve, reject)=>{
        api.post("/admin/grievance/status",payload).then( (res)=>{
            if(!res.data.error){
                resolve(res.data);
            } else {
                reject({
                    message: res.data.msg
                })
            }
        }).catch( (error) => {
            reject({
                message: "Something Went Wrog",
                error : error
            })
        })
    })
}

const updateAssignee = (payload) =>{
    return new Promise((resolve, reject)=>{
        api.post("/admin/grievance/assign",payload).then( (res)=>{
            if(!res.data.error){
                resolve(res.data);
            } else {
                reject({
                    message: res.data.msg
                })
            }
        }).catch( (error) => {
            reject({
                message: "Something Went Wrog",
                error : error
            })
        })
    })
}



const grievanceServices = {
    getList,
    getUserGrievanceList,
    getAssignedGrievanceList,
    getById,
    getByPhone,
    getActivityById,
    updateStatus,
    updateAssignee,
    getDashBoardData
}
export default grievanceServices;