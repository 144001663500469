import React, { useEffect, useState } from 'react'
import { Dialog, DialogActions, DialogContent, DialogTitle, IconButton, LinearProgress, Tooltip } from "@mui/material";
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import FormikControl from '../../../../components/FormikControl/FormikControl';
import { Add, Edit } from '@mui/icons-material';
import { useAlert } from '../../../../context/AlertContext';
import userServices from '../userServices';


function UserForm({data,refreshList,masters,roleDDList}) {

    const {AppAlert} = useAlert();
    const [formValues, setFormValues] = useState(null);
    const [dialogStatus, setDialogStatus] = useState(false);
    const [savingInProgress, setSavingStatus] = useState(false);
    const [changePassword, setChangePassword] = useState(false);


    const initialValues = {
        email:"",
        password:"",
        firstName:"",
        lastName:"",
        phone:"",
        designation:"",
        role:"",
        workLocation:"",
    }

    const validationRule = Yup.object({
        email:Yup.string().required("Required"),
        firstName:Yup.string().required("Required"),
        workLocation:Yup.string().required("Required"),
        phone:Yup.string().required("Required"),
        designation:Yup.string().required("Required"),
        role:Yup.string().required("Required"),
    });

    const addNew = ()=>{
        setFormValues(initialValues);
        setChangePassword(true);
        setDialogStatus(true)
    }

    const updateData = ()=>{
        setChangePassword(false);
        let autoFill = {
            id:data.id,
            email:data.email,
            password:"",
            firstName:data.firstName,
            lastName:data.lastName,
            workLocation:data.workLocation,
            role:data.role,
            phone:data.phone,
            designation:data.designation,
        }
        console.log(autoFill);
        setFormValues(autoFill);
        setDialogStatus(true)
    }

    const handleClose  = () =>{
        setDialogStatus(false);
    }

    const onSubmit = (values, onSubmitProps) => {
        values.changePassword = changePassword;
        console.log(values);
        
        userServices.save(values).then(res=>{
            AppAlert(res.msg);
            setDialogStatus(false);
            refreshList()
        }).catch(error=>{
            AppAlert(error.msg,"error");
        }).finally(()=>{
            setSavingStatus(false);
        })
    }



    return (
        <>
            {!data &&
                <Tooltip title="Add New">
                    <button type="button" className="btn btn-primary" onClick={addNew} ><Add/> Add New</button>
                </Tooltip>
            }
            {data &&
                <Tooltip title="Update">
                    <IconButton onClick={updateData} style={{padding:"0px"}}>
                        <Edit/>
                    </IconButton>
                </Tooltip>
            }
            <Dialog
                open={dialogStatus}
                keepMounted
                onClose={handleClose}
                fullWidth={true}
                maxWidth="md"
                aria-describedby="alert-dialog-slide-description"
            >

                <Formik
                    initialValues={formValues || initialValues}
                    validationSchema={validationRule}
                    onSubmit={onSubmit}
                    enableReinitialize validateOnMount
                >
                    {
                        (formik) => {
                            //console.log(formik.values);
                            return (
                                <Form>
                                    <DialogTitle>{"Add New"}</DialogTitle>
                                    <DialogContent>
                                        <div className="row p-2">
                                            
                                            <div className="col-md-12">

                                                <div className="row">
                                                    


                                                    <FormikControl control="input" formik={formik} type="text" 
                                                        label="First Name" name="firstName" 
                                                        className="col-md-6 col-xs-12 p-2" 
                                                    />

                                                    <FormikControl control="input" formik={formik} type="text" 
                                                        label="Last Name" name="lastName" 
                                                        className="col-md-6 col-xs-12 p-2" 
                                                    />

                                                    <FormikControl control="input" formik={formik} type="text" 
                                                        label="Email" name="email" 
                                                        className="col-md-6 col-xs-12 p-2" 
                                                    />


                                                    <FormikControl control="input" formik={formik} type="text" 
                                                        label="Counter No. / Room No / Work Station" name="workLocation" 
                                                        className="col-md-6 col-xs-12 p-2" 
                                                    />
    

                                                    {roleDDList &&  
                                                        <FormikControl control="autoselect" formik={formik} 
                                                            label="Role" name="role"  
                                                            options={roleDDList} multiple={false} 
                                                            className="col-md-3 col-xs-6 p-2"
                                                        />
                                                    }


                                                    {masters &&  
                                                        <FormikControl control="autoselect" formik={formik} 
                                                            label="Designation" name="designation"  
                                                            options={masters['designation']} multiple={false} 
                                                            className="col-md-3 col-xs-6 p-2"
                                                        />
                                                    }

                                                    {!changePassword &&  <button type="button" className="btn btn-link" onClick={()=>setChangePassword(true)} >Change Password </button> }
                                                    {changePassword &&
                                                        <FormikControl control="input" formik={formik} type="text" 
                                                            label="Password" name="password" 
                                                            className="col-md-6 col-xs-12 p-2" 
                                                        />
                                                    }
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row">
                                        { /*  JSON.stringify(formik.values) */}
                                        </div>

                                        {savingInProgress && <div className="row"><LinearProgress/></div> }
                                    </DialogContent>
                                    <DialogActions>
                                        
                                        
                                        {!savingInProgress && 
                                            <>
                                            <button className="btn btn-success" type="submit">Save</button>
                                            <button className="btn btn-danger" type="button" onClick={handleClose}>Cancel</button>
                                            </>
                                        }
                                        
                                    </DialogActions>
                                </Form>
                            )
                        }
                    }
                </Formik>
            </Dialog>

        </>
    )
}

export default UserForm