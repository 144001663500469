import React from 'react'
import AppLayout from '../../../layout/components/AppLayout'
import { Link } from 'react-router-dom'
import { Dashboard, DataObject, DoorSliding, Dvr, FollowTheSigns, ListAlt, Newspaper, Quiz, TransferWithinAStation, Tv } from '@mui/icons-material'

function WhatsAppUserLandingPage() {
  return (
    <AppLayout>
      <div className='container'>
        <div className='row mt-5 text-center'>
          <div className='col-md-6 my-3'>
                <div className='card bg-primary p-3 ' >
                    <Link to={"/admin/chat-log"} className='btn text-white'   >
                    <h3><DataObject style={{fontSize:60}} /></h3>
                    <p>Chat Log</p>
                    </Link>
                </div>
            </div>

            <div className='col-md-6 my-3'>
                <div className='card bg-primary p-3 ' >
                    <Link to={"/admin/new-enquiry"} className='btn text-white'   >
                    <h3><Quiz style={{fontSize:60}} /></h3>
                    <p>New Query List</p>
                    </Link>
                </div>
            </div>
        </div>
      </div>
    </AppLayout>  
  )
}

export default WhatsAppUserLandingPage