import React from 'react'
import { Route, Routes } from 'react-router'
import GrievanceAdminRoutes from './GrievanceAdminRoutes'
import ManagerLandingPage from '../app/dashboard/pages/ManagerLandingPage'
import { useAuth } from '../context/AuthContext'
import WhatsAppAdminRoutes from './WhatsAppAdminRoutes'
import GrievanceDataGridPage from '../app/admin/grievance/pages/GrievanceDataGridPage'


function ManagerRoutes() {
  const {authUser} = useAuth()
  return (
    <>
      <Routes>
        {/* Manager */}
        {authUser.role == 2 && <Route path="/" element={ <ManagerLandingPage /> }></Route>  }
        <Route path="/grievance/data-grid" element={ <GrievanceDataGridPage /> }></Route>
      </Routes>
      <WhatsAppAdminRoutes/>
      <GrievanceAdminRoutes/>
    </>
  )
}

export default ManagerRoutes