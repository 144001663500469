import React from 'react'
import { Route, Routes } from 'react-router'
import NewEnquiryListPage from '../app/admin/newEnquiry/pages/NewEnquiryListPage'
import ChatLogPage from '../app/admin/chatLog/pages/ChatLogPage'
import WhatsAppUserLandingPage from '../app/dashboard/pages/WhatsAppUserLandingPage';
import { useAuth } from '../context/AuthContext'


function WhatsAppUserRoutes() {
  const {authUser} = useAuth();
  return (
    <>
      <Routes>
        {/* WhatApp User */}
        {authUser.role == 7 && <Route path="/" element={ <WhatsAppUserLandingPage /> }></Route>  }
        <Route path="/admin/new-enquiry" element={ <NewEnquiryListPage /> }></Route>
        <Route path="/admin/chat-log" element={ <ChatLogPage /> }></Route>
      </Routes>
    </>
  )
}

export default WhatsAppUserRoutes
