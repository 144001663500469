import {useState,useEffect} from 'react'
import hookServices from './hookServices';


function useBusinessInfo() {
    const [businessInfo,setBusineeInfo] = useState(null);
    const getBusinessInfo = () =>{
        let _businessInfo  =  JSON.parse(sessionStorage.getItem("businessInfo"));
        if(_businessInfo == null){
            hookServices.getBusinessInfo().then(res=>{
                setBusineeInfo(res.data);
                sessionStorage.setItem("businessInfo",JSON.stringify(res.data))
            }).catch(error=>{
                // we can use logout here
                console.log("Erroe while fatching business info")
            })
        } else {
            //console.log("_businessInfo is ",_businessInfo)
            setBusineeInfo(_businessInfo);
        }
    }
    useEffect(()=>{
        getBusinessInfo();
    },[])
    return businessInfo;
}

export default useBusinessInfo