import React from 'react'
import { Route, Routes } from 'react-router'
import ResponseMessageListPage from '../app/admin/response/pages/ResponseMessageListPage'
import ResponseMessageFormPage from '../app/admin/response/pages/ResponseMessageFormPage'
import IntentListPage from '../app/admin/Intent/pages/IntentListPage'
import MediaLibraryPage from '../app/admin/response/pages/MediaLibraryPage'
import WhatsAppUserRoutes from './WhatsAppUserRoutes'
import WhatsAppAdminLandingPage from '../app/dashboard/pages/WhatsAppAdminLandingPage'
import { useAuth } from '../context/AuthContext'


function WhatsAppAdminRoutes() {
  const {authUser} = useAuth();
  return (
    <>
      <Routes>
        {/* WhatsApp Admin */}
        {authUser.role == 3 && <Route path="/" element={ <WhatsAppAdminLandingPage /> }></Route>  }
        <Route path="/admin/response-message" element={ <ResponseMessageListPage /> }></Route>
        <Route path="/admin/response-message/form" element={ <ResponseMessageFormPage /> }></Route>
        <Route path="/admin/response-message/form/:id" element={ <ResponseMessageFormPage /> }></Route>=
        <Route path="/admin/intent" element={ <IntentListPage /> }></Route>
        <Route path="/admin/media-library" element={ <MediaLibraryPage /> }></Route>
      </Routes>
      <WhatsAppUserRoutes/>
    </>
  )
}

export default WhatsAppAdminRoutes