import React,{useState,useEffect} from 'react'
import AppLayout from '../../../../layout/components/AppLayout'
import DateSelect from '../../../../components/AppInput/DateSelect'
import AppSelect from '../../../../components/AppInput/AppSelect'
import useSystemMasterList from '../../../../hooks/useSystemMasterList'
import greivanceServices from '../grievanceServices'
import GrievanceTabularList from '../components/GrievanceTabularList'
import GrievanceCardList from '../components/GrievanceCardList'
import { Dashboard, Map, ViewList } from '@mui/icons-material'
import GrivanceMapList from '../components/GrievanceMapList'
import grievanceStatsServices from '../grivanceStatsServices'
import { IconButton, Tooltip } from '@mui/material'



export default function GreivanceListPage() {

    const [masters,getMasterLabel] = useSystemMasterList(['grievance-type','grievance-status']);

    const [filter,setFilter] = useState(null);
    const [greivanceType,setGreivanceType] = useState(null);
    const [greivanceStatus,setGreivanceStatus] = useState(null);
    const [grievanceList,setGrievanceList] = useState(null);

    const [listType,setListType] = useState("table");

    const [grievanceStats,setGrivanceStats] = useState(null);

    const getGrievanceStats = () =>{
        if(grievanceList && masters){
            let stats = grievanceStatsServices.generateStatsFromList(grievanceList,masters);
            setGrivanceStats(stats);
        }
    }

    useEffect(()=>{
        getGrievanceStats()
    },[grievanceList,masters])


    const getGreivanceList = () =>{
        if(masters){
            setGrievanceList(null);
            const payload = {
                grievanceType:greivanceType===null ? 0: greivanceType.value,
                grievanceStatus:greivanceStatus===null?0:greivanceStatus.value,
                startDate:filter.startDate,
                endDate:filter.endDate
            }
            greivanceServices.getList(payload).then(res=>{
                setGrievanceList(res.data);
                
            }).catch(error=>{

            }).finally(()=>{

            })
        }
    }

    useEffect(()=>{
        getGreivanceList();
    },[filter,greivanceType,greivanceStatus,masters])

    return (
        <AppLayout>
            <div className="container-fluid">
                <div className='row'>
                    <div className='col-md-4 mb-3'>
                        {masters &&
                            <AppSelect
                                options={[{value:0,label:"All"},...masters['grievance-type']]}
                                value={greivanceType}
                                setValue={setGreivanceType}
                                label="Select Catrgory" 
                                optionLabel = "label"
                            />
                        }
                    </div>
                    <div className='col-md-4 mb-3'>
                        {masters &&
                            <AppSelect
                                options={[{value:0,label:"All"},...masters['grievance-status']]}
                                value={greivanceStatus}
                                setValue={setGreivanceStatus}
                                label="Select Status" 
                                optionLabel = "label"
                            />
                        }
                    </div>
                    <div className='col-md-4'>
                        <span>

                            <Tooltip title="Table View">
                                <IconButton color="primary" onClick={()=>setListType("table")}>
                                    <ViewList/>
                                </IconButton>
                            </Tooltip>

                            <Tooltip title="Card View">
                                <IconButton color="primary" onClick={()=>setListType("card")}>
                                    <Dashboard/>
                                </IconButton>
                            </Tooltip>

                            <Tooltip title="Map View">
                                <IconButton color="primary" onClick={()=>setListType("map")}>
                                    <Map/>
                                </IconButton>
                            </Tooltip>
                        </span>
                        <span className='float-end'><DateSelect filter={filter} setFilter={setFilter} /></span>
                    </div>
                </div>
                {listType === "table" && grievanceStats &&
                    <GrievanceTabularList grievanceList={grievanceList} getMasterLabel={getMasterLabel} grievanceStats={grievanceStats} />
                }
                {listType === "card" && grievanceStats &&
                    <GrievanceCardList grievanceList={grievanceList} getMasterLabel={getMasterLabel} grievanceStats={grievanceStats} />
                }

                {listType === "map" && grievanceStats &&
                    <GrivanceMapList grievanceList={grievanceList} getMasterLabel={getMasterLabel} grievanceStats={grievanceStats} />
                }
                
            </div>
        </AppLayout>
    )
}
