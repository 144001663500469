import React, { useState,useEffect } from 'react'
import AppLayout from '../../../../layout/components/AppLayout'
import { useAlert } from '../../../../context/AlertContext';
import grievanceServices from '../grievanceServices';
import useSystemMasterList from '../../../../hooks/useSystemMasterList';
import grievanceStatsServices from '../grivanceStatsServices';
import GrievanceTabularList from '../components/GrievanceTabularList';

function SearchGrievancePage() {

	const [masters,getMasterLabel] = useSystemMasterList(['grievance-type','grievance-status']);
	const {AppAlert} = useAlert();
	const [phoneInput,setPhoneInput] = useState("");
	const[grievanceList,setGrievanceList] = useState(null);
	const [grievanceStats,setGrivanceStats] = useState(null);

	const handlePhoneInput = (e) => {
        let val = e.target.value;
        if(val.length <= 10) {
            setPhoneInput(val)
        }
    }

	const doSearch = () =>{
		if(phoneInput.length < 10){
			AppAlert("Invalid Phone Number","error")
			return false
		}
		setGrievanceList(null);
		grievanceServices.getByPhone(phoneInput).then(res=>{
			setGrievanceList(res.data);
		}).catch(()=>{
			AppAlert("Something Went wrong, Try Again")
		})
	}

	const getGrievanceStats = () =>{
        if(grievanceList && masters){
            let stats = grievanceStatsServices.generateStatsFromList(grievanceList,masters);
            setGrivanceStats(stats);
        }
    }

    useEffect(()=>{
        getGrievanceStats()
    },[grievanceList,masters])

  	return (
		<AppLayout>
			<div className='container'>
				<div className='row'>
					<div className="col-md-6">
						<div className="form-floating mb-3">
							<input type="number" className="form-control" placeholder="" 
								value={phoneInput}
								onChange={handlePhoneInput}
                                onKeyUp={ (e) =>{
									if(e.key === 'Enter') {
                                        e.preventDefault();
                                        doSearch();
                                    }
                                }}
							/>
							<label >Complainant Phone Number</label>
						</div>
					</div>
				</div>
			</div>
			<div>
			 	{grievanceStats &&
					<GrievanceTabularList grievanceList={grievanceList} getMasterLabel={getMasterLabel} grievanceStats={grievanceStats} />
				}
			</div>
		</AppLayout>
	)
}

export default SearchGrievancePage