
import api from "../../../api/api";


const getRemarkByGrievanceId = (id) =>{
    return new Promise((resolve, reject)=>{
        api.get("/admin/grievance/remark/"+id).then( (res)=>{
            if(!res.data.error){
                resolve(res.data);
            } else {
                reject({
                    message: res.data.msg
                })
            }
        }).catch( (error) => {
            reject({
                message: "Something Went Wrog",
                error : error
            })
        })
    })
}


const save = (payload) =>{
    return new Promise((resolve, reject)=>{
        api.post("/admin/grievance/remark",payload).then( (res)=>{
            if(!res.data.error){
                resolve(res.data);
            } else {
                reject({
                    message: res.data.msg
                })
            }
        }).catch( (error) => {
            reject({
                message: "Something Went Wrog",
                error : error
            })
        })
    })
}



const grievanceRemarkServices = {
    getRemarkByGrievanceId,
    save
}
export default grievanceRemarkServices;