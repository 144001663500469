const generateStatsFromList = (grievanceList,masters) =>{
    let stats = {
        progress :{
            pending : 0,
            inProgress: 0,
            closed:0,
            total:0,
        }
    };
    grievanceList.forEach(grievance => {
        stats.progress.total+= 1
        switch(grievance.grievanceStatus){
            case 1:
                stats.progress.pending+= 1;
                break;
            case 2:
                stats.progress.inProgress+= 1;
                break;
            case 3:
                stats.progress.closed+= 1;
                break;
        }
    });


    let grievanceTypeList = [...masters['grievance-type']]
    grievanceTypeList.forEach((grievanceType,i) => {
        grievanceTypeList[i].count = 0;
    });

    grievanceTypeList.forEach((grievanceType,i) =>{
        grievanceList.forEach(grievance=>{
            if(grievanceType.value == grievance.grievanceType){
                grievanceTypeList[i].count+=1;
            }
        })
    })
    stats.grievanceTypeList = grievanceTypeList;
    return stats
}



const grievanceStatsServices = {
    generateStatsFromList
}
export default grievanceStatsServices



/*

for(var master in masters){
        console.log(master);
    }

*/