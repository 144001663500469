import React, { useState, useEffect } from 'react'
import useSystemMasterList from '../../../../hooks/useSystemMasterList';
import { CheckBox, CheckBoxOutlineBlank } from '@mui/icons-material';
import { Chip, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import userServices from '../userServices';
import { useAlert } from '../../../../context/AlertContext';

function AssignGrievanceType({userDetails,callback}) {
    const [dialogStatus, setDialogStatus] = useState(false);
    const [masters,getMasterLabel] = useSystemMasterList(['grievance-type','grievance-status']);
    const [optionList,setOptionList] = useState(null);
    const {AppAlert} = useAlert();

    const [assignedGrievanceType,setAssignedGrievanceType] = useState([]);

    useState(()=>{
        if(userDetails.assignedGrievanceType !== null) {
            setAssignedGrievanceType(userDetails.assignedGrievanceType.split(",").map(Number))
        }
    },[userDetails.assignedGrievanceType])



    useEffect(()=>{
        if(masters!==null ){
            let _optionList = [...masters['grievance-type']];
            _optionList = _optionList.map((option)=> {
                    console.log(assignedGrievanceType);
                    if(assignedGrievanceType.includes(option.value)){
                        return {...option,status:true}
                    } else {
                        return {...option,status:false}
                    }
                }
            )
            setOptionList(_optionList);
        }
    },[masters,assignedGrievanceType])

    const handleOptionClick = (i) => {
        let _optionList = [...optionList];
        _optionList[i].status = !_optionList[i].status;
        setOptionList(_optionList)
    }

    const saveAssignedList= () =>{
        let _assignedOptionList = [];
        optionList.forEach((option,i) => {
            if(option.status == true){
                return _assignedOptionList.push(option.value)
            }
        });
        let payload = {
            id:userDetails.id,
            assignedGrievanceType : _assignedOptionList.length > 0 ? _assignedOptionList.join(",") : null,
            changePassword:false
        }

        userServices.save(payload).then(res=>{
            AppAlert("Assigned Grievance list Updated Successfully");
            setDialogStatus(false);
            callback()
        }).catch(error=>{
            AppAlert("Something went wrong");
        }).finally(()=>{

        })
    }

    return (
        <>
            {optionList && optionList.map(option =>{
                if(option.status){
                    return ( <Chip label={option.label} color="primary" className='me-2' /> )
                }
            })}
            <button className='btn btn-outline-primary ms-3' onClick={()=>setDialogStatus(true)}>Assigne Grievance Type</button>
            <Dialog
                open={dialogStatus}
                keepMounted
                onClose={()=>setDialogStatus(false)}
                maxWidth="sm"
                fullWidth={true}
            >
                <DialogTitle>Assign Greivance Category</DialogTitle>
                <DialogContent>
                    <ul className="list-group">
                        {optionList && optionList.map((option,i)=>
                            <button
                                key={option.value}
                                type='button' className="list-group-item list-group-item-action"
                                onClick={()=>handleOptionClick(i)}
                            >   {option.status ? <CheckBox/> : <CheckBoxOutlineBlank/> } {option.label}</button>
                        )}
                    </ul>
                </DialogContent>
                <DialogActions>
                    <button className='btn btn-primary' onClick={saveAssignedList} >Save</button>
                </DialogActions>
            </Dialog>
            
        </>
    )
}

export default AssignGrievanceType