import React,{useState,useEffect} from 'react'
import AppLayout from '../../../../layout/components/AppLayout'
import DateSelect from '../../../../components/AppInput/DateSelect'
import AppSelect from '../../../../components/AppInput/AppSelect'
import useSystemMasterList from '../../../../hooks/useSystemMasterList'
import greivanceServices from '../grievanceServices'
import GrievanceTabularList from '../components/GrievanceTabularList'
import GrievanceCardList from '../components/GrievanceCardList'
import { Dashboard, Map, ViewList } from '@mui/icons-material'
import GrivanceMapList from '../components/GrievanceMapList'
import grievanceStatsServices from '../grivanceStatsServices'



export default function UserGrievanceListPage() {

    const [masters,getMasterLabel] = useSystemMasterList(['grievance-type','grievance-status']);

    const [filter,setFilter] = useState(null);
    
    const [greivanceStatus,setGreivanceStatus] = useState(null);
    const [grievanceList,setGrievanceList] = useState(null);

    const [listType,setListType] = useState("table");

    const [grievanceStats,setGrivanceStats] = useState(null);

    const getGrievanceStats = () =>{
        if(grievanceList && masters){
            let stats = grievanceStatsServices.generateStatsFromList(grievanceList,masters);
            setGrivanceStats(stats);
        }
    }

    useEffect(()=>{
        getGrievanceStats()
    },[grievanceList,masters])


    const getGreivanceList = () =>{
        if(masters){
            setGrievanceList(null);
            const payload = {
                grievanceStatus:greivanceStatus===null?0:greivanceStatus.value,
                startDate:filter.startDate,
                endDate:filter.endDate
            }
            greivanceServices.getUserGrievanceList(payload).then(res=>{
                setGrievanceList(res.data);
                
            }).catch(error=>{

            }).finally(()=>{

            })
        }
    }

    useEffect(()=>{
        getGreivanceList();
    },[filter,greivanceStatus,masters])

    return (
        <AppLayout>
            <div className="container-fluid">
                <div className='row'>
                    <div className='col-md-4 mb-3'>
                        {masters &&
                            <AppSelect
                                options={[{value:0,label:"All"},...masters['grievance-status']]}
                                value={greivanceStatus}
                                setValue={setGreivanceStatus}
                                label="Select Status" 
                                optionLabel = "label"
                            />
                        }
                    </div>
                    <div className='col-md-4 mb-3'>
                    </div>
                    <div className='col-md-4'>
                        <span>
                            <button onClick={()=>setListType("table")} className={ listType=="table"? 'btn btn-primary m-2': 'btn btn-outline-primary m-2'}><ViewList/></button>
                            <button onClick={()=>setListType("card")}  className={ listType=="card"? 'btn btn-primary m-2': 'btn btn-outline-primary m-2'}><Dashboard/></button>
                            <button onClick={()=>setListType("map")}  className={ listType=="map"? 'btn btn-primary m-2': 'btn btn-outline-primary m-2'}><Map/> </button>
                        </span>
                        <span className='float-end'><DateSelect filter={filter} setFilter={setFilter} /></span>
                    </div>
                </div>
                {listType === "table" && grievanceStats &&
                    <GrievanceTabularList grievanceList={grievanceList} getMasterLabel={getMasterLabel} grievanceStats={grievanceStats} />
                }
                {listType === "card" && grievanceStats &&
                    <GrievanceCardList grievanceList={grievanceList} getMasterLabel={getMasterLabel} grievanceStats={grievanceStats} />
                }

                {listType === "map" && grievanceStats &&
                    <GrivanceMapList grievanceList={grievanceList} getMasterLabel={getMasterLabel} grievanceStats={grievanceStats} />
                }
                
            </div>
        </AppLayout>
    )
}
