import React from 'react'
import { Route, Routes } from 'react-router'
import { useAuth } from '../context/AuthContext'
import UserGrievanceListPage from '../app/admin/grievance/pages/UserGrievanceListPage';
import GrievanceInspectorRoutes from './GrievanceInspectorRoutes';
import GrievanceSupervisorLandingPage from '../app/dashboard/pages/GrievanceSupervisorLandingPage';
import SearchGrievancePage from '../app/admin/grievance/pages/SearchGrievancePage';


function GrievanceSupervisorRoutes() {
  const {authUser} = useAuth();
  return (
    <>
      <Routes>
        {/* WhatApp User */}
        {authUser.role == 5 && <Route path="/" element={ <GrievanceSupervisorLandingPage /> }></Route>  }
        <Route path="/grievance/my-list" element={ <UserGrievanceListPage /> }></Route>
        <Route path="/grievance/search" element={ <SearchGrievancePage /> }></Route>
      </Routes>
      <GrievanceInspectorRoutes/>
    </>
  )
}

export default GrievanceSupervisorRoutes
