import React from 'react'
import useStaffList from '../../../../hooks/useStaffList'
import { WhatsApp } from '@mui/icons-material';


const ContactDetail = ({staff}) =>{
  let _whatsapp_link = "https://api.whatsapp.com/send/?phone=91"+staff.whatsappNumber+"&text=Hello";
  return (
    <div className='border rounded p-2 my-1'> 
      <span className='fw-bold'> {staff.firstName} ({staff.designationTitle}) </span>   
      {staff.phone !== null &&
          <a href="tel:{staff.phone}">{staff.phone}</a>
      }
      {staff.whatsappNumber !== null &&
          <a href={_whatsapp_link} target="_blank" className='ms-3 btn btn-sm btn-success' ><WhatsApp/></a>
          
       
      }
    </div>
  )

}


function GrievanceContactList({grievanceDetails}) {
    const [staffList,getStaffLabel] = useStaffList();
    return (
      <>
        {grievanceDetails &&
        <div>
          {staffList?.map(staff=>
            <>
              {staff.assignedGrievanceType.includes(grievanceDetails.grievanceType) &&
                <ContactDetail staff={staff} />
              }
            </>
          )}

          {/*
          {staffList?.map(staff=>
            <>
              {!staff.assignedGrievanceType.includes(grievanceDetails.grievanceType) &&
                <ContactDetail staff={staff} />
              }
            </>
          )}
          */}
        </div>
        }
      </>
    )
}

export default GrievanceContactList