import React from 'react'
import AppLayout from '../../../layout/components/AppLayout'
import { Link } from 'react-router-dom'
import { Assignment, AssignmentTurnedIn, Dashboard, DoorSliding, Dvr, FollowTheSigns, ListAlt, Newspaper, Search, TransferWithinAStation, Tv } from '@mui/icons-material'

function GrievanceSupervisorLandingPage() {
  return (
    <AppLayout>
      <div className='container'>
        <div className='row mt-5 text-center'>
            <div className='col-md-6 my-3'>
              <div className='card bg-primary p-3 ' >
                <Link to={"/grievance/my-list"} className='btn text-white'   >
                  <h3><Assignment style={{fontSize:60}} /></h3>
                  <p>My Grievance (Your Department Grievances)</p>
                </Link>
              </div>
            </div>
          
            <div className='col-md-6 my-3'>
              <div className='card bg-primary p-3 ' >
                <Link to={"/grievance/assigned-list"} className='btn text-white'   >
                  <h3><AssignmentTurnedIn style={{fontSize:60}} /></h3>
                  <p>Assigned List</p>
                </Link>
              </div>
            </div>

            <div className='col-md-6 my-3'>
              <div className='card bg-primary p-3 ' >
                <Link to={"/grievance/assigned-list"} className='btn text-white'   >
                  <h3><Search style={{fontSize:60}} /></h3>
                  <p>Search</p>
                </Link>
              </div>
            </div>
        </div>
      </div>
    </AppLayout>  
  )
}

export default GrievanceSupervisorLandingPage