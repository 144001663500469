import React from 'react'
import AppLayout from '../../../layout/components/AppLayout'
import { Link } from 'react-router-dom'
import { Dashboard, DoorSliding, Dvr, FollowTheSigns, ListAlt, Newspaper, TransferWithinAStation, Tv } from '@mui/icons-material'

function ManagerLandingPage() {
  return (
    <AppLayout>
      <div className='container'>
        <div className='row mt-5 text-center'>
          <div className='col-md-6 my-3'>
              <div className='card bg-primary p-3 ' >
                <Link to={"/admin/intent"} className='btn text-white'   >
                  <h3><Dashboard style={{fontSize:60}} /></h3>
                  <p>Intents</p>
                </Link>
              </div>
          </div>
          <div className='col-md-6 my-3'>
              <div className='card bg-primary p-3 ' >
                <Link to={"/admin/response-message"} className='btn text-white'   >
                  <h3><Dashboard style={{fontSize:60}} /></h3>
                  <p>Response Messages</p>
                </Link>
              </div>
          </div>

          <div className='col-md-6 my-3'>
              <div className='card bg-primary p-3 ' >
                <Link to={"/grievance/list"} className='btn text-white'   >
                  <h3><Dashboard style={{fontSize:60}} /></h3>
                  <p>Grievance List</p>
                </Link>
              </div>
          </div>
          
        </div>
      </div>
    </AppLayout>  
  )
}

export default ManagerLandingPage