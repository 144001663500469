import { CircularProgress, Dialog, DialogContent, DialogTitle } from '@mui/material';
import React,{useState} from 'react'
import grievanceServices from '../grievanceServices';
import { useAlert } from '../../../../context/AlertContext';

function GrievanceStatusInput({grievanceDetails,masters,getMasterLabel,callback}) {
	const [dialogStatus, setDialogStatus] = useState(false);
	const {AppAlert} = useAlert()
	const [saving,setSavingStatus] = useState(false);

	const updateGrievanceStatus = (newStatus) =>{
		setSavingStatus(true);
		grievanceServices.updateStatus({id:grievanceDetails.id,grievanceStatus:newStatus}).then(res=>{
			AppAlert("Status Updated")
			setDialogStatus(false);
			callback();
		}).catch(error=>{
			AppAlert("Something went Wrong","error");
		}).finally(()=>{
			setSavingStatus(false)
		})
	}
	return (
		<>
			<button className='btn btn-outline-primary'
				onClick={()=>setDialogStatus(true)}
			>
				{getMasterLabel("grievance-status",grievanceDetails.grievanceStatus)} 
			</button>
			<Dialog
                open={dialogStatus}
                keepMounted
                onClose={()=>setDialogStatus(false)}
                maxWidth="sm"
            >
                <DialogTitle>Update Status</DialogTitle>
				<DialogContent>
					{saving &&<div className='"text-center p-5'> <CircularProgress/></div>}
					{!saving &&
						<ul className="list-group">
							{masters['grievance-status'].map(grievanceStatus=>
								<button key={grievanceStatus.value} 
									type='button' className="list-group-item list-group-item-action"
									onClick={()=>updateGrievanceStatus(grievanceStatus.value)}
								>
									{grievanceStatus.label}
								</button>
							)}
						</ul>
					}
				</DialogContent>
			</Dialog>

		</>
	)
}

export default GrievanceStatusInput